// extracted by mini-css-extract-plugin
export var header = "header-module--header--1SiOw";
export var subpage = "header-module--subpage--12afj";
export var inner = "header-module--inner--24Lgc";
export var title = "header-module--title--1MOu5";
export var jsconfBp = "header-module--jsconfBp--u1f3V";
export var jsconf = "header-module--jsconf--oQF9O";
export var logo = "header-module--logo--1f9Le";
export var conf = "header-module--conf--3Ddbh";
export var budapest = "header-module--budapest--2qxl5";
export var date = "header-module--date--oWBbR";
export var year = "header-module--year--pRDYm";
export var mobileMenuTrigger = "header-module--mobileMenuTrigger--2LxqH";
export var triggerCheckbox = "header-module--triggerCheckbox--2leQA";
export var navigation = "header-module--navigation--3qxue";
export var menu = "header-module--menu--3cJAs";
export var logoSvg = "header-module--logoSvg--1ZhtZ";
export var polygons = "header-module--polygons--1shwI";
export var cta = "header-module--cta--Pf2Ng";
export var button = "header-module--button--2uCiX";
export var hero = "header-module--hero--3xgGJ";
export var mask = "header-module--mask--2fij5";
export var content = "header-module--content--3Xqtp";
export var heroContent = "header-module--heroContent--39Cu4";
export var shadow = "header-module--shadow--hIbWk";