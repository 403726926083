// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-call-for-speakers-2021-js": () => import("./../../../src/pages/call-for-speakers-2021.js" /* webpackChunkName: "component---src-pages-call-for-speakers-2021-js" */),
  "component---src-pages-code-of-conduct-js": () => import("./../../../src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-empty-page-js": () => import("./../../../src/pages/empty-page.js" /* webpackChunkName: "component---src-pages-empty-page-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-scholarships-js": () => import("./../../../src/pages/scholarships.js" /* webpackChunkName: "component---src-pages-scholarships-js" */),
  "component---src-pages-sponsorship-js": () => import("./../../../src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-templates-speakers-js": () => import("./../../../src/templates/speakers.js" /* webpackChunkName: "component---src-templates-speakers-js" */),
  "component---src-templates-updates-js": () => import("./../../../src/templates/updates.js" /* webpackChunkName: "component---src-templates-updates-js" */),
  "component---src-templates-workshops-js": () => import("./../../../src/templates/workshops.js" /* webpackChunkName: "component---src-templates-workshops-js" */)
}

