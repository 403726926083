// extracted by mini-css-extract-plugin
export var workshop_page = "workshops-module--workshop_page--m0pHA";
export var main_content = "workshops-module--main_content--3l9wb";
export var workshop_title = "workshops-module--workshop_title--1gdBl";
export var workshop_meta = "workshops-module--workshop_meta--2X8C9";
export var company_logo = "workshops-module--company_logo--31JwM";
export var subtitle = "workshops-module--subtitle--2cUXQ";
export var links = "workshops-module--links--2b0pA";
export var register = "workshops-module--register--3aHs0";
export var register_button = "workshops-module--register_button--1ytUG";
export var mentor_list = "workshops-module--mentor_list--3QCfu";
export var ticket_button = "workshops-module--ticket_button--360bt";