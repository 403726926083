// extracted by mini-css-extract-plugin
export var sponsoration = "past_summary-module--sponsoration--3iXQ8";
export var past_summary = "past_summary-module--past_summary--151Hq";
export var big_summary = "past_summary-module--big_summary--1w414";
export var block_inner = "past_summary-module--block_inner--2uQrt";
export var title = "past_summary-module--title--1LW18";
export var col_speakers = "past_summary-module--col_speakers--3UfLj";
export var col_attendees = "past_summary-module--col_attendees--1kbGo";
export var col_countries = "past_summary-module--col_countries--1BoXN";
export var stat_number = "past_summary-module--stat_number--3FOCt";
export var label = "past_summary-module--label--3-YfK";
export var video = "past_summary-module--video--3qQ7D";