// extracted by mini-css-extract-plugin
export var sponsoration = "packageinfo-module--sponsoration--1N-kR";
export var packages = "packageinfo-module--packages--ZUNsT";
export var packages_table = "packageinfo-module--packages_table--1pXtj";
export var block_inner = "packageinfo-module--block_inner--5CeH2";
export var package_levels = "packageinfo-module--package_levels---q5lM";
export var info_title = "packageinfo-module--info_title--1zWqC";
export var info_title__scholarships = "packageinfo-module--info_title__scholarships--26vQ8";
export var info_text = "packageinfo-module--info_text--2uTtH";
export var package_silver_name = "packageinfo-module--package_silver_name--3tTxg";
export var package_silver_price = "packageinfo-module--package_silver_price--RWY2y";
export var package_silver_branding_label = "packageinfo-module--package_silver_branding_label--2oZeb";
export var package_silver_branding_value = "packageinfo-module--package_silver_branding_value--1ddQn";
export var package_silver_branding_included = "packageinfo-module--package_silver_branding_included--14qkD";
export var package_silver_tickets_label = "packageinfo-module--package_silver_tickets_label--o1_Eu";
export var package_silver_tickets_value = "packageinfo-module--package_silver_tickets_value--1EpND";
export var package_silver_tickets_included = "packageinfo-module--package_silver_tickets_included--t94tz";
export var package_silver_screen_label = "packageinfo-module--package_silver_screen_label--27Rxf";
export var package_silver_screen_value = "packageinfo-module--package_silver_screen_value--2hKh-";
export var package_silver_screen_included = "packageinfo-module--package_silver_screen_included--2ISl5";
export var package_silver_booth_label = "packageinfo-module--package_silver_booth_label--2WYLf";
export var package_silver_booth_value = "packageinfo-module--package_silver_booth_value--3vseN";
export var package_silver_booth_included = "packageinfo-module--package_silver_booth_included--2qI72";
export var package_silver_slide_label = "packageinfo-module--package_silver_slide_label--1OEhU";
export var package_silver_slide_value = "packageinfo-module--package_silver_slide_value--RA2xr";
export var package_silver_slide_included = "packageinfo-module--package_silver_slide_included--TE_NP";
export var package_silver_hr_label = "packageinfo-module--package_silver_hr_label--2sSWz";
export var package_silver_hr_value = "packageinfo-module--package_silver_hr_value--307-z";
export var package_silver_hr_included = "packageinfo-module--package_silver_hr_included--13hDq";
export var package_gold_name = "packageinfo-module--package_gold_name--8R6Ss";
export var package_gold_price = "packageinfo-module--package_gold_price--1SGUw";
export var package_gold_branding_label = "packageinfo-module--package_gold_branding_label--wRg1G";
export var package_gold_branding_value = "packageinfo-module--package_gold_branding_value--3KXMg";
export var package_gold_branding_included = "packageinfo-module--package_gold_branding_included--3D4Sw";
export var package_gold_tickets_label = "packageinfo-module--package_gold_tickets_label--2tdFe";
export var package_gold_tickets_value = "packageinfo-module--package_gold_tickets_value--a3tFh";
export var package_gold_tickets_included = "packageinfo-module--package_gold_tickets_included--1wos2";
export var package_gold_screen_label = "packageinfo-module--package_gold_screen_label--2LTRp";
export var package_gold_screen_value = "packageinfo-module--package_gold_screen_value--13oWH";
export var package_gold_screen_included = "packageinfo-module--package_gold_screen_included--X9xdT";
export var package_gold_booth_label = "packageinfo-module--package_gold_booth_label--3KTW_";
export var package_gold_booth_value = "packageinfo-module--package_gold_booth_value--290Lk";
export var package_gold_booth_included = "packageinfo-module--package_gold_booth_included--3_22z";
export var package_gold_slide_label = "packageinfo-module--package_gold_slide_label--azkzy";
export var package_gold_slide_value = "packageinfo-module--package_gold_slide_value--1cZUA";
export var package_gold_slide_included = "packageinfo-module--package_gold_slide_included--26eiE";
export var package_gold_hr_label = "packageinfo-module--package_gold_hr_label--3Le9w";
export var package_gold_hr_value = "packageinfo-module--package_gold_hr_value--3roSv";
export var package_gold_hr_included = "packageinfo-module--package_gold_hr_included--219JY";
export var package_workshop_name = "packageinfo-module--package_workshop_name--3yUbZ";
export var package_workshop_price = "packageinfo-module--package_workshop_price--3UJOW";
export var package_workshop_branding_label = "packageinfo-module--package_workshop_branding_label--1f8ER";
export var package_workshop_branding_value = "packageinfo-module--package_workshop_branding_value--LJOwo";
export var package_workshop_branding_included = "packageinfo-module--package_workshop_branding_included--2DG3i";
export var package_workshop_tickets_label = "packageinfo-module--package_workshop_tickets_label--h-4Gt";
export var package_workshop_tickets_value = "packageinfo-module--package_workshop_tickets_value--_6L-4";
export var package_workshop_tickets_included = "packageinfo-module--package_workshop_tickets_included--1TrFc";
export var package_workshop_screen_label = "packageinfo-module--package_workshop_screen_label--3HBRd";
export var package_workshop_screen_value = "packageinfo-module--package_workshop_screen_value--1rl0F";
export var package_workshop_screen_included = "packageinfo-module--package_workshop_screen_included--2B1nI";
export var package_workshop_booth_label = "packageinfo-module--package_workshop_booth_label--2zMch";
export var package_workshop_booth_value = "packageinfo-module--package_workshop_booth_value--3iOrb";
export var package_workshop_booth_included = "packageinfo-module--package_workshop_booth_included--1rx6Z";
export var package_workshop_slide_label = "packageinfo-module--package_workshop_slide_label--np-0i";
export var package_workshop_slide_value = "packageinfo-module--package_workshop_slide_value--1bJU9";
export var package_workshop_slide_included = "packageinfo-module--package_workshop_slide_included--3EFmF";
export var package_workshop_hr_label = "packageinfo-module--package_workshop_hr_label--3D3Zd";
export var package_workshop_hr_value = "packageinfo-module--package_workshop_hr_value--5zL00";
export var package_workshop_hr_included = "packageinfo-module--package_workshop_hr_included--7NrGE";
export var package_platinum_name = "packageinfo-module--package_platinum_name--2E20j";
export var package_platinum_price = "packageinfo-module--package_platinum_price--17JCa";
export var package_platinum_branding_label = "packageinfo-module--package_platinum_branding_label--RJAQX";
export var package_platinum_branding_value = "packageinfo-module--package_platinum_branding_value--39r64";
export var package_platinum_branding_included = "packageinfo-module--package_platinum_branding_included--2Sum0";
export var package_platinum_tickets_label = "packageinfo-module--package_platinum_tickets_label--2eIBK";
export var package_platinum_tickets_value = "packageinfo-module--package_platinum_tickets_value--3-Cb-";
export var package_platinum_tickets_included = "packageinfo-module--package_platinum_tickets_included--123X0";
export var package_platinum_screen_label = "packageinfo-module--package_platinum_screen_label--eYWZ3";
export var package_platinum_screen_value = "packageinfo-module--package_platinum_screen_value--3LlVo";
export var package_platinum_screen_included = "packageinfo-module--package_platinum_screen_included--2NvZC";
export var package_platinum_booth_label = "packageinfo-module--package_platinum_booth_label--3hXSB";
export var package_platinum_booth_value = "packageinfo-module--package_platinum_booth_value--3D23X";
export var package_platinum_booth_included = "packageinfo-module--package_platinum_booth_included--1UDOv";
export var package_platinum_slide_label = "packageinfo-module--package_platinum_slide_label--Bt8tl";
export var package_platinum_slide_value = "packageinfo-module--package_platinum_slide_value--2szvT";
export var package_platinum_slide_included = "packageinfo-module--package_platinum_slide_included--I4u50";
export var package_platinum_hr_label = "packageinfo-module--package_platinum_hr_label--3ex7x";
export var package_platinum_hr_value = "packageinfo-module--package_platinum_hr_value--3H49w";
export var package_platinum_hr_included = "packageinfo-module--package_platinum_hr_included--2Ur4p";