// extracted by mini-css-extract-plugin
export var sponsoration = "pastsponsors-module--sponsoration--1XMx9";
export var sponsor = "pastsponsors-module--sponsor--1eMcj";
export var block_inner = "pastsponsors-module--block_inner--xP9o3";
export var sponsor_subtitle = "pastsponsors-module--sponsor_subtitle--1kWHp";
export var sponsor_list = "pastsponsors-module--sponsor_list--3eUIR";
export var sponsor_list_big = "pastsponsors-module--sponsor_list_big--1kwfp";
export var sponsor_list_medium = "pastsponsors-module--sponsor_list_medium--2fO6n";
export var sponsor_big = "pastsponsors-module--sponsor_big--3wSmm";
export var sponsor_med = "pastsponsors-module--sponsor_med--7Oca3";
export var sponsor_sm = "pastsponsors-module--sponsor_sm--1GiB_";
export var sponsor_scholarship = "pastsponsors-module--sponsor_scholarship--3D3mQ";
export var sponsor_partner = "pastsponsors-module--sponsor_partner--2aLLD";