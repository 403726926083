// extracted by mini-css-extract-plugin
export var sponsoration = "packages-module--sponsoration--8Q6SK";
export var packages = "packages-module--packages--2SfnY";
export var packages_table = "packages-module--packages_table--XzFt0";
export var info_title = "packages-module--info_title--218FM";
export var info_title__scholarships = "packages-module--info_title__scholarships--3_sgR";
export var block_inner = "packages-module--block_inner--340yS";
export var title = "packages-module--title--HvdW-";
export var package_levels = "packages-module--package_levels--pgNKv";
export var scholarships = "packages-module--scholarships--19y1c";
export var info_text = "packages-module--info_text--2oMtd";
export var packages_button = "packages-module--packages_button--3N8w4";
export var package_silver_name = "packages-module--package_silver_name--E9VKr";
export var package_silver_price = "packages-module--package_silver_price--3zmvA";
export var package_silver_branding_label = "packages-module--package_silver_branding_label--1T-eE";
export var package_silver_branding_value = "packages-module--package_silver_branding_value--3B4vd";
export var package_silver_branding_included = "packages-module--package_silver_branding_included--RVLFY";
export var package_silver_tickets_label = "packages-module--package_silver_tickets_label--1qsKA";
export var package_silver_tickets_value = "packages-module--package_silver_tickets_value--1jFc5";
export var package_silver_tickets_included = "packages-module--package_silver_tickets_included--1zaJO";
export var package_silver_screen_label = "packages-module--package_silver_screen_label--3P6Cs";
export var package_silver_screen_value = "packages-module--package_silver_screen_value--VRf6A";
export var package_silver_screen_included = "packages-module--package_silver_screen_included--1RMhg";
export var package_silver_booth_label = "packages-module--package_silver_booth_label--2LjNA";
export var package_silver_booth_value = "packages-module--package_silver_booth_value--34S7A";
export var package_silver_booth_included = "packages-module--package_silver_booth_included--3IQWN";
export var package_silver_slide_label = "packages-module--package_silver_slide_label--nFiuf";
export var package_silver_slide_value = "packages-module--package_silver_slide_value--3AV6p";
export var package_silver_slide_included = "packages-module--package_silver_slide_included--2ubET";
export var package_silver_hr_label = "packages-module--package_silver_hr_label--5MxM7";
export var package_silver_hr_value = "packages-module--package_silver_hr_value--2F5Xx";
export var package_silver_hr_included = "packages-module--package_silver_hr_included--3AKmg";
export var package_gold_name = "packages-module--package_gold_name--2UIO1";
export var package_gold_price = "packages-module--package_gold_price--3ea1V";
export var package_gold_branding_label = "packages-module--package_gold_branding_label--U6OD5";
export var package_gold_branding_value = "packages-module--package_gold_branding_value--HodoS";
export var package_gold_branding_included = "packages-module--package_gold_branding_included--2LrDi";
export var package_gold_tickets_label = "packages-module--package_gold_tickets_label--36Ysp";
export var package_gold_tickets_value = "packages-module--package_gold_tickets_value--2r_74";
export var package_gold_tickets_included = "packages-module--package_gold_tickets_included--265xu";
export var package_gold_screen_label = "packages-module--package_gold_screen_label--14LAI";
export var package_gold_screen_value = "packages-module--package_gold_screen_value--erOXd";
export var package_gold_screen_included = "packages-module--package_gold_screen_included--1rzy8";
export var package_gold_booth_label = "packages-module--package_gold_booth_label--1uqIk";
export var package_gold_booth_value = "packages-module--package_gold_booth_value--9N4im";
export var package_gold_booth_included = "packages-module--package_gold_booth_included--2zyav";
export var package_gold_slide_label = "packages-module--package_gold_slide_label--2bvuq";
export var package_gold_slide_value = "packages-module--package_gold_slide_value----7R1";
export var package_gold_slide_included = "packages-module--package_gold_slide_included--1jOkS";
export var package_gold_hr_label = "packages-module--package_gold_hr_label--3q77v";
export var package_gold_hr_value = "packages-module--package_gold_hr_value--2t-6f";
export var package_gold_hr_included = "packages-module--package_gold_hr_included--2ovz_";
export var package_workshop_name = "packages-module--package_workshop_name--I6Nhl";
export var package_workshop_price = "packages-module--package_workshop_price--3C5Um";
export var package_workshop_branding_label = "packages-module--package_workshop_branding_label--1kq2o";
export var package_workshop_branding_value = "packages-module--package_workshop_branding_value--1wdfw";
export var package_workshop_branding_included = "packages-module--package_workshop_branding_included--3kpOA";
export var package_workshop_tickets_label = "packages-module--package_workshop_tickets_label--2JXJ1";
export var package_workshop_tickets_value = "packages-module--package_workshop_tickets_value--3E4T3";
export var package_workshop_tickets_included = "packages-module--package_workshop_tickets_included--1Q9F_";
export var package_workshop_screen_label = "packages-module--package_workshop_screen_label--3SyxP";
export var package_workshop_screen_value = "packages-module--package_workshop_screen_value--3dpik";
export var package_workshop_screen_included = "packages-module--package_workshop_screen_included--197o2";
export var package_workshop_booth_label = "packages-module--package_workshop_booth_label--13npy";
export var package_workshop_booth_value = "packages-module--package_workshop_booth_value--1rb0n";
export var package_workshop_booth_included = "packages-module--package_workshop_booth_included--2EV2I";
export var package_workshop_slide_label = "packages-module--package_workshop_slide_label--2vN1j";
export var package_workshop_slide_value = "packages-module--package_workshop_slide_value--3d9Fo";
export var package_workshop_slide_included = "packages-module--package_workshop_slide_included--3ShmJ";
export var package_workshop_hr_label = "packages-module--package_workshop_hr_label--2Doel";
export var package_workshop_hr_value = "packages-module--package_workshop_hr_value--swaod";
export var package_workshop_hr_included = "packages-module--package_workshop_hr_included--13OZF";
export var package_platinum_name = "packages-module--package_platinum_name--O2bxf";
export var package_platinum_price = "packages-module--package_platinum_price--2IDFw";
export var package_platinum_branding_label = "packages-module--package_platinum_branding_label--2IYAG";
export var package_platinum_branding_value = "packages-module--package_platinum_branding_value--344r6";
export var package_platinum_branding_included = "packages-module--package_platinum_branding_included--1rPJJ";
export var package_platinum_tickets_label = "packages-module--package_platinum_tickets_label--2Ci5L";
export var package_platinum_tickets_value = "packages-module--package_platinum_tickets_value--2ey_F";
export var package_platinum_tickets_included = "packages-module--package_platinum_tickets_included--19kM1";
export var package_platinum_screen_label = "packages-module--package_platinum_screen_label--3M0HF";
export var package_platinum_screen_value = "packages-module--package_platinum_screen_value--QkSSh";
export var package_platinum_screen_included = "packages-module--package_platinum_screen_included--1pLa6";
export var package_platinum_booth_label = "packages-module--package_platinum_booth_label--3ztiW";
export var package_platinum_booth_value = "packages-module--package_platinum_booth_value--9c5PW";
export var package_platinum_booth_included = "packages-module--package_platinum_booth_included--14X07";
export var package_platinum_slide_label = "packages-module--package_platinum_slide_label--3C5qS";
export var package_platinum_slide_value = "packages-module--package_platinum_slide_value--MkTHE";
export var package_platinum_slide_included = "packages-module--package_platinum_slide_included--2IhKE";
export var package_platinum_hr_label = "packages-module--package_platinum_hr_label--1HpFi";
export var package_platinum_hr_value = "packages-module--package_platinum_hr_value--24Nrb";
export var package_platinum_hr_included = "packages-module--package_platinum_hr_included--2DAgB";