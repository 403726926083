import React from 'react'
import Helmet from 'react-helmet'

const SecondPage = () => (
  <>
    <Helmet title={'new page'} />
  </>
)

export default SecondPage
