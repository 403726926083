// extracted by mini-css-extract-plugin
export var sponsoration = "combinations-module--sponsoration--2CcSX";
export var combinations = "combinations-module--combinations--3j0J_";
export var block_inner = "combinations-module--block_inner--3GGRe";
export var package_levels = "combinations-module--package_levels--1mq-F";
export var info_title = "combinations-module--info_title--3t1QL";
export var info_text = "combinations-module--info_text--3C_7S";
export var example = "combinations-module--example--5dgDm";
export var input = "combinations-module--input--pq6GH";
export var offer = "combinations-module--offer--3JQFr";
export var total = "combinations-module--total--2r32C";
export var result = "combinations-module--result--1Aq2y";