// extracted by mini-css-extract-plugin
export var workshop_list = "workshoplist-module--workshop_list--1LrGy";
export var workshop_list_item = "workshoplist-module--workshop_list_item--1XmnW";
export var workshop_title = "workshoplist-module--workshop_title--2tGG6";
export var workshop_date = "workshoplist-module--workshop_date--23cMn";
export var workshop_date_month = "workshoplist-module--workshop_date_month--1Vgeo";
export var workshop_date_day = "workshoplist-module--workshop_date_day--2EX-K";
export var workshop_date_year = "workshoplist-module--workshop_date_year--2pYWV";
export var workshop_date_label = "workshoplist-module--workshop_date_label--3jqjX";
export var seats = "workshoplist-module--seats--2WvQZ";
export var seats_count = "workshoplist-module--seats_count--26WVj";
export var workshop_lead = "workshoplist-module--workshop_lead--GMqiy";
export var workshop_register = "workshoplist-module--workshop_register--3_Asu";
export var register_button = "workshoplist-module--register_button--1WBi2";
export var workshop_link = "workshoplist-module--workshop_link--2zjuE";