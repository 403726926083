// extracted by mini-css-extract-plugin
export var sponsoration = "attendees-module--sponsoration--aLFUn";
export var attendees = "attendees-module--attendees--1v7B9";
export var countries = "attendees-module--countries--3iBDR";
export var stats = "attendees-module--stats--3vUci";
export var block_inner = "attendees-module--block_inner--3ytf3";
export var stats_title = "attendees-module--stats_title--1hUdB";
export var graphs = "attendees-module--graphs--3aS1F";
export var graphs__top = "attendees-module--graphs__top--2Lmct";
export var stats__age = "attendees-module--stats__age--oEc2P";
export var stats__position = "attendees-module--stats__position--242qv";