// extracted by mini-css-extract-plugin
export var sponsoration = "reach-module--sponsoration--3yuRk";
export var reach = "reach-module--reach--2lRnZ";
export var notes = "reach-module--notes--15iQv";
export var numbers = "reach-module--numbers--3juDn";
export var block_inner = "reach-module--block_inner--3RgYs";
export var title = "reach-module--title--2iHoi";
export var twitter = "reach-module--twitter--13s1B";
export var youtube = "reach-module--youtube--2F667";
export var facebook = "reach-module--facebook--2qm6G";
export var followers = "reach-module--followers--2FBPP";
export var subscribers = "reach-module--subscribers--3SUHU";
export var reached = "reach-module--reached--3k7QW";